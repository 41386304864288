<template>
  <div class='profile'>
    <img class="banner" src="../assets/img/profile.png">
    <div class="title">公司简介</div>
    <p>高仕登是中国商务部领导下的全资国企，主要完成外商企业在华投资、培训、人才培养、科研成果转化等工作，推动国际人力资源交流及中外经济融合发展。与国内外政界、经贸界、文教科技界、商会团体、国际组织有着广泛的合作和友好关系，借鉴国际先进理念服务于国民教育及经济发展。</p>
    <p>为响应国家教育国际化号召，推荐国外多所大学与中国部分大学展开校际间的留学服务和专业学科共建项目，由国内985/211和世界名校共同整合顶尖资源，致力于促进国际化创新人才培养。</p>
    <p>积极引进国外产业方、引导国内大型企业与高校推进专业共建、产业学院等产教融合实践，深化职业化教育改革，培养大国工匠，为工业强国夯实人才基础。</p>
    <p>高仕登依托于国内外数字学科带头人队伍在信息安全网络安全中发挥信息化、智慧化建设的骨干力量。建设以网络数据中心为基础的智慧校园系统，实现信息技术与教育教学的深度融合。搭建同频互动平台和云教研平台，充分发挥大学智库和专业机构融合优势，稳步推进信息技术与教育教学的深度融合和创新应用。 积极打造线上线下、课内课外、校内校外的智能学习创新平台，不断增强信息技术的生活化和现代化，创建基于信息技术的智慧校园。</p>
    <p>高仕登多年来与国家开发银行、中国农业发展银行、中国光大银行、中信银行等金融机构保持良好合作，致力于校园基础设施、产业学院、智慧校园等，共同为中国教育事业贡献力量。</p>
    <p>高仕登（河南）将在北京总部的指导下，借鉴国内、国外服务经验，响应省厅号召，站在全球视野解决河南高考难题，推进中外合作办学教育实践、加速特色产业学院落地、构建智慧校园生活方式、推动教投基金，为河南教育事业不遗余力！</p>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.profile {
  width: 1280px;
  margin: 0 auto;

  .banner {
    width: 1280px;
    height: 300px;
    margin: 40px 0;
  }

  .title {
    color: #185DD4;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    text-indent: 2em;
    line-height: 30px;
    margin-bottom: 40px;
  }
}
</style>
